<template>
    <div :class="classess"></div>
</template>

<script>
export default {
    name: 'BaseShopStatus',
    props: {
        status: {
            type: String,
            default: 'not_ready',
        },
    },
    computed: {
        classess() {
            return {
                'base-shop-status': true,
                [`base-shop-status_${this.status}`]: true,
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.base-shop-status {
    position: relative;
    width: 8px;
    height: 8px;

    &::before {
        content: '';
        position: absolute;
        right: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }

    &_good {
        &::before {
            background: rgb(68, 255, 68);
        }
    }

    &_bad {
        &::before {
            background: rgb(255, 58, 58);
        }
    }

    &_not_ready {
        &::before {
            background: rgb(255, 210, 28);
        }
    }
}
</style>

<template>
    <q-dialog :modelValue="hasError" seamless position="bottom">
        <q-card style="width: 300px" class="bg-grey-9">
            <q-card-section class="row items-center no-wrap text-white">
                <div>{{ error }}</div>

                <q-space />

                <q-btn flat label="Ок" color="amber" @click="dismissPopup" />
            </q-card-section>
        </q-card>
    </q-dialog>
</template>

<script>
import { useCommonStore } from '@/stores/common'
import { storeToRefs } from 'pinia'
export default {
    name: 'TheErrorAlert',
    setup() {
        const commonStore = useCommonStore()
        const { setError } = commonStore
        const { error, hasError } = storeToRefs(commonStore)

        const dismissPopup = () => setError(null)

        return {
            error,
            hasError,
            dismissPopup,
        }
    },
}
</script>

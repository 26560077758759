<template>
    <q-card class="base-lazy-gallery" v-if="image">
        <q-card-section>
            <BasePhoto :src="image" :ratio="4 / 3" />
        </q-card-section>
        <q-card-actions align="center" class="q-pb-lg">
            <q-btn
                round
                outline
                :disable="!isPrevAvailable"
                color="primary"
                icon="navigate_before"
                @click="onClickPrev"
            />
            <q-btn
                round
                outline
                :disable="!isNextAvailable"
                color="primary"
                icon="navigate_next"
                @click="onClickNext"
            />
        </q-card-actions>
    </q-card>
</template>

<script>
const events = {
    next: 'next',
    prev: 'prev',
}

export default {
    name: 'BaseLazyGallery',
    props: {
        image: {
            type: String,
            default: '',
        },
        isPrevAvailable: {
            type: Boolean,
            default: false,
        },
        isNextAvailable: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        onClickNext() {
            this.$emit(events.next)
        },
        onClickPrev() {
            this.$emit(events.prev)
        },
        onKeyUp(e) {
            if (e.keyCode === 39) {
                this.onClickNext()
                return
            }

            if (e.keyCode === 37) {
                this.onClickPrev()
                return
            }
        },
    },
    mounted() {
        window.addEventListener('keyup', this.onKeyUp)
    },
    unmounted() {
        window.removeEventListener('keyup', this.onKeyUp)
    },
}
</script>

<style lang="scss" scoped>
.base-lazy-gallery {
    width: 800px;
    max-width: 100vw;
}
</style>

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import Quasar from 'quasar/src/vue-plugin.js';
import vClickOutside from 'click-outside-vue3'
import router from '@/router'
import initBaseComponents from '@/components/Base'
import quasarUserOptions from './quasar-user-options'
import App from './App.vue'

const app = createApp(App)

initBaseComponents(app)

const pinia = createPinia()

app.use(Quasar, quasarUserOptions)
    .use(router)
    .use(pinia)
    .use(vClickOutside)
    .mount('#app')

import { UserApi } from '@/api/services'
import { useCommonStore } from '@/stores/common'
import { useUserStore } from '@/stores/user'
import { logError } from '@/utils/common'

const setAuthorizationHeader = (request) => {
    const { accessToken } = useUserStore()

    if (accessToken) {
        request.headers.Authorization = `Bearer ${accessToken}`
    }

    return request
}

const checkTokenRefreshIsNeeded = ({ response, config }) => {
    return response && response.status === 401 && config && !config.__isRetry
}

export const checkCannotConnectToServer = (error) => {
    return (
        error.code === 'ERR_NETWORK' ||
        (error.response && error.response.status >= 500)
    )
}

export const onConnectionError = () => {
    const { setError } = useCommonStore()
    setError('Нет соединения с сервером')
}

const handleResponseError = async (api, error) => {
    if (checkCannotConnectToServer(error)) {
        onConnectionError()
        return Promise.reject(error)
    }

    if (!checkTokenRefreshIsNeeded(error)) {
        return Promise.reject(error)
    }

    error.config.__isRetry = true

    try {
        await handleRefresh()
    } catch {
        logError(error)

        const { logoutUser, refreshToken } = useUserStore()

        if (refreshToken) {
            logoutUser()
        }

        return Promise.reject(error)
    }

    return api(error.config)
}

const CHECK_IF_REFRESHED_INTVERVAL_MS = 10
let isRefreshingToken = false

const handleRefresh = async () => {
    const { refreshToken, saveAccessToken, saveRefreshToken } = useUserStore()

    if (!refreshToken) {
        throw new Error('No refresh token')
    }

    if (!isRefreshingToken) {
        isRefreshingToken = true

        try {
            const {
                data: { access_token, refresh_token },
            } = await UserApi.refreshToken({
                refresh: refreshToken,
            })
            saveAccessToken(access_token)
            saveRefreshToken(refresh_token)
        } catch (error) {
            throw new Error(error)
        } finally {
            isRefreshingToken = false
        }

        return
    }

    return new Promise((resolve) => {
        const checkIfRefreshed = setInterval(() => {
            if (isRefreshingToken) return

            clearInterval(checkIfRefreshed)
            resolve()
        }, CHECK_IF_REFRESHED_INTVERVAL_MS)
    })
}

export { setAuthorizationHeader, handleResponseError }

<template>
    <q-input filled readonly :modelValue="modelValue" mask="fulltime">
        <template v-slot:append>
            <q-icon name="access_time" class="cursor-pointer" size="0.6em">
                <q-popup-proxy
                    cover
                    transition-show="scale"
                    transition-hide="scale"
                >
                    <q-time
                        :modelValue="modelValue"
                        @update:modelValue="onUpdate"
                        :options="options"
                        format24h
                        v-bind="$attrs"
                    >
                        <div class="row items-center justify-end">
                            <q-btn
                                v-close-popup
                                label="Закрыть"
                                color="primary"
                                flat
                            />
                        </div>
                    </q-time>
                </q-popup-proxy>
            </q-icon>
        </template>
    </q-input>
</template>

<script>
const events = {
    updateModelValue: 'update:modelValue',
}
export default {
    name: 'BaseTimePicker',
    props: {
        modelValue: {
            type: String,
            default: '',
        },
        options: {
            type: Function,
            default: () => {
                return true
            },
        },
    },
    emits: Object.values(events),
    methods: {
        onUpdate(e) {
            this.$emit(events.updateModelValue, e)
        },
    },
}
</script>

import { createRouter, createWebHistory } from 'vue-router'
import { useUserStore } from '@/stores/user'

const routes = [
    {
        path: '/',
        component: () => import('@/views/Shops'),
        name: 'Shops',
        meta: { requiresAuth: true },
    },
    {
        path: '/login',
        component: () => import('@/views/Login'),
        name: 'Login',
    },
    {
        path: '/report/:token',
        component: () => import('@/views/Report'),
        name: 'Report',
        meta: { requiresAuth: true },
    },
]

const router = createRouter({
    history: createWebHistory(),
    scrollBehavior() {
        return { top: 0, behavior: 'smooth' }
    },
    routes,
})

router.beforeEach((to) => {
    if (!to.meta.requiresAuth) return true

    const { isUserLoggedIn } = useUserStore()

    if (isUserLoggedIn) return true

    return {
        name: 'Login',
        query: {
            redirectTo: to.fullPath,
        },
    }
})

export default router

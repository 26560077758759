export default function initBaseComponents(vueApp) {
    const requireComponent = require.context(
        '@/components/Base',
        false,
        /Base[A-Z]\w+\.(vue)$/,
    )

    requireComponent.keys().forEach((fileNameWithPath) => {
        const pathLength = fileNameWithPath.split('/').length
        const fileName = `./${fileNameWithPath.split('/')[pathLength - 1]}`
        const componentConfig = requireComponent(fileNameWithPath)
        const componentName = fileName
            .replace(/^\.\//, '')
            .replace(/\.\w+$/, '')

        vueApp.component(
            componentName,
            componentConfig.default || componentConfig,
        )
    })
}

<template>
    <q-layout view="hHh lpr fff">
        <DefaultHeader />

        <q-page-container class="default-layout-container">
            <q-page class="default-layout-page">
                <router-view />
            </q-page>
        </q-page-container>
    </q-layout>
</template>

<script>
import DefaultHeader from './DefaultHeader'

export default {
    components: { DefaultHeader },
    name: 'DefautLayout',
}
</script>

<style lang="scss" scoped>
.default-layout {
    &-container {
        max-width: $containerMaxWidth;
        margin: auto;
        padding: 0 12px;
    }
}
</style>

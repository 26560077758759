import api from '@/api'

/**
 * @typedef { import('axios').AxiosResponse } AxiosResponse
 */

/**
 * Fetches shops
 * @param {Object} data
 * @param {Object} data.page - current page
 * @param {Object} data.itemsPerPage - items per page
 * @param {Object} data.searchQuery - search query
 * @returns {Promise<AxiosResponse<ShopsList>} shops
 */
const getShops = ({ page, itemsPerPage, searchQuery, notReadyOnly }) => {
    const params = {
        pageStartIndex: page,
        itemsPerPage,
        searchQuery,
    }

    if (notReadyOnly) {
        params.notReadyOnly = true
    }

    return api.get('/v1/stores/filtered', {
        params,
    })
}

/**
 * Fetches store cameras
 * @param {Object} data
 * @param {String} data.storeToken - store token
 * @returns {Promise<AxiosResponse<Array<String>} list of cameras
 */
const getAvailableCameras = ({ storeToken }) => {
    return api.get(`/v1/stores/${storeToken}/cameras`)
}

const getShop = ({ storeToken }) => {
    return api.get(`/v1/stores/${storeToken}`)
}

export { getShops, getAvailableCameras, getShop }

<template>
    <q-input
        v-bind="$attrs"
        :modelValue="modelValue"
        @update:modelValue="onInput"
        :debounce="debounce"
    />
</template>

<script>
const events = {
    updateModelValue: 'update:modelValue',
}

export default {
    name: 'BaseInput',
    props: {
        modelValue: {
            type: [String, Number],
            default: '',
        },
        debounce: {
            type: Number,
            default: undefined,
        },
    },
    emits: Object.values(events),
    methods: {
        onInput(e) {
            this.$emit(events.updateModelValue, e)
        },
    },
}
</script>

<template>
    <div>
        <q-select
            :modelValue="modelValue"
            @update:modelValue="onSelect"
            v-bind="$attrs"
            :options="options"
            :loading="loading"
            @blur="onBlur"
        >
            <template #no-option>
                <q-item>
                    <q-item-section class="text-grey">
                        Нет результатов
                    </q-item-section>
                </q-item>
            </template>
        </q-select>
    </div>
</template>

<script>
const events = {
    updateModelValue: 'update:modelValue',
    blur: 'blur',
}

export default {
    name: 'BaseSelect',
    props: {
        modelValue: {
            type: [String, Object, Number],
            default: '',
        },
        options: {
            type: Object,
            default: () => ({}),
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    emits: Object.values(events),
    methods: {
        onSelect(e) {
            this.$emit(events.updateModelValue, e)
        },
        onBlur() {
            this.$emit(events.blur)
        },
    },
}
</script>

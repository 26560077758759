<template>
    <div class="text-h6 q-mb-sm" v-if="title">{{ title }}</div>
    <q-table
        :columns="columns"
        :rows="rows"
        v-bind="$attrs"
        :hideBottom="hideBottom"
        :separator="separator"
        :pagination="pagination"
        @update:pagination="onUpdatePagination"
        @request="onRequest"
    >
        <template v-for="(_, slot) of $slots" v-slot:[slot]="scope">
            <slot :name="slot" v-bind="scope" />
        </template>
    </q-table>
</template>

<script>
const events = {
    request: 'request',
    updatePagination: 'update:pagination',
}
export default {
    name: 'BaseTable',
    props: {
        header: {
            type: Array,
            default: () => [],
        },
        rows: {
            type: Array,
            default: () => [],
        },
        columns: {
            type: Array,
            default: () => [],
        },
        title: {
            type: String,
            default: '',
        },
        hideBottom: {
            type: Boolean,
            default: false,
        },
        separator: {
            type: String,
            default: undefined,
        },
        pagination: {
            type: Object,
            default: () => ({ rowsPerPage: 0 }),
        },
    },
    emits: Object.values(events),
    methods: {
        onRequest(e) {
            this.$emit(events.request, e)
        },
        onUpdatePagination(e) {
            this.$emit(events.updatePagination, e)
        },
    },
}
</script>

import axios from 'axios'
import { handleResponseError, setAuthorizationHeader } from './interceptors'
import { convertDataToFormParams } from '@/api/convertDataToFormParams'

const configure = {
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Allows: 'application/x-www-form-urlencoded',
    },
}

const api = axios.create(configure)

api.interceptors.request.use((request) => {
    return setAuthorizationHeader(request)
})

api.interceptors.response.use(undefined, async (error) => {
    return handleResponseError(api, error)
})

api.interceptors.request.use((config) => {
    config.paramsSerializer = convertDataToFormParams

    return config
})

export default api

<template>
    <div class="relative-position" :class="{ 'light-dimmed': isLoading }">
        <div class="row justify-center loader-overlay__icon" v-if="isLoading">
            <q-spinner-dots color="primary" size="2em" />
        </div>
        <slot />
    </div>
</template>

<script>
export default {
    name: 'BasePageLoader',
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style lang="scss" scoped>
.loader-overlay__icon {
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 400;
}
</style>

<template>
    <BaseChart
        v-bind="$attrs"
        chartType="bar"
        :labels="chartLabels"
        :datasets="chartDatasets"
        :options="chartOptions"
    />
    <div class="q-mt-sm">
        <BaseRange
            :min="min"
            :max="max"
            :modelValue="modelValue"
            @update:modelValue="onRangeSelect"
        />
    </div>
</template>

<script>
const events = {
    updateModelValue: 'update:modelValue',
}

export default {
    name: 'BaseHistogramSlider',
    props: {
        barsData: {
            type: Array,
            default: () => [],
        },
        modelValue: {
            type: Object,
            default: () => ({}),
        },
        min: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
            default: 1,
        },
        thumbsize: {
            type: Number,
            default: 0,
        },
        labelFormatter: {
            type: Function,
            default: (value) => {
                return value
            },
        },
        barColors: {
            type: Function,
            required: true,
        },
    },
    emits: Object.values(events),
    data() {
        return {
            rangeSliderElement: null,
        }
    },
    computed: {
        chartData() {
            return this.barsData.map(({ value }) => value)
        },
        chartColors() {
            const activeColor = '#105efb'
            const inactiveColor = '#eeeeee'

            const min = this.modelValue.min || Number.MIN_SAFE_INTEGER
            const max = this.modelValue.max || Number.MAX_SAFE_INTEGER

            return this.barsData.map(({ label, value }) => {
                return this.barColors({ min, max, value, label })
                    ? activeColor
                    : inactiveColor
            })
        },
        chartDatasets() {
            return [
                {
                    label: 'histogram',
                    data: this.chartData,
                    backgroundColor: this.chartColors,
                },
            ]
        },
        chartLabels() {
            return this.barsData.map(({ label }) => label)
        },
        chartOptions() {
            return {
                aspectRatio: 4 / 1,
                plugins: {
                    autocolors: { enabled: false },
                    legend: {
                        display: false,
                    },
                    datalabels: {
                        display: false,
                    },
                    tooltip: {
                        enabled: false,
                    },
                },
                scales: {
                    x: {
                        display: false,
                    },
                    y: {
                        display: false,
                        type: 'linear',
                        grace: 10,
                    },
                },
                animation: {
                    duration: 100,
                },
            }
        },
    },
    methods: {
        onRangeSelect(e) {
            this.$emit(events.updateModelValue, e)
        },
    },
}
</script>

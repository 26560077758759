import api from '@/api'

/**
 * @typedef { import('axios').AxiosResponse } AxiosResponse
 */

/**
 * Requests diagnostics
 * @param {Object} data
 * @param {Object} data.storeToken - store token
 * @returns {Promise<AxiosResponse>}
 */
const postDiagnostics = ({ storeToken }) => {
    return api.post(`/v1/monitoring/${storeToken}/request`)
}

/**
 * Fetches diagnostics data
 * @param {Object} data
 * @param {String} data.storeToken - store token
 * @returns {Promise<AxiosResponse<Array<Diagnostics>} list of visitors
 */
const getDiagnostics = ({ storeToken, pageStartIndex, itemsPerPage }) => {
    const params = {
        pageStartIndex,
        itemsPerPage,
    }

    return api.get(`/v1/monitoring/${storeToken}/records`, { params })
}

export { postDiagnostics, getDiagnostics }

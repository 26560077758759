import api from '@/api'
import { REPORT_OUTPUT_FORMATS } from '@/constants/marketingReport'

/**
 * @typedef { import('axios').AxiosResponse } AxiosResponse
 */

/**
 * Fetches marketing report
 * @param {Object} data
 * @param {String} data.storeToken - store token
 * @param {String} data.startDate - report date
 * @returns {Promise<AxiosResponse<MarketingReport>} marketing report
 */
const getReport = ({ storeToken, startDate }) => {
    return api.get(`/v1/marketing/store/${storeToken}/report`, {
        params: {
            date: startDate,
        },
    })
}

/**
 * Fetches faces logs
 * @param {Object} data
 * @param {String} data.storeToken - store token
 * @param {Number} data.page - current page
 * @param {Number} data.itemsPerPage - items per page
 * @param {Object} data.filters - logs filters
 * @param {Boolean} data.desc - sorting direction
 * @returns {Promise<AxiosResponse<PersonsLogs>} logs
 */
const getPersonsLogData = ({
    storeToken,
    page,
    itemsPerPage,
    filters,
    // sortBy,
    desc,
    outputFormat = REPORT_OUTPUT_FORMATS.JSON,
}) => {
    const params = {
        camera: filters.camera,
        dateRange: {
            min: filters.date.min,
            max: filters.date.max,
        },
        gender: filters.gender,
        age: {
            min: filters.age.min,
            max: filters.age.max,
        },
        width: {
            min: filters.width.min,
            max: filters.width.max,
        },
        page,
        itemsPerPage,
        isDescSort: desc,
    }

    let requestURL = `/v1/marketing/store/${storeToken}/faces`
    let responseType = 'json'

    if (outputFormat === REPORT_OUTPUT_FORMATS.CSV) {
        requestURL = `/v1/marketing/store/${storeToken}/faces/csv`
        responseType = 'arraybuffer'
    }

    return api.get(requestURL, { params, responseType })
}

export { getReport, getPersonsLogData }

import { onMounted, onUnmounted } from 'vue'

export function logError(error) {
    if (process.env.NODE_ENV == 'development') {
        console.error(error)
    }
}

/**
 * Adds backslash to special symbols used in regex expressions
 * @param {string} string - string to escape characters in
 * @returns {string} escaped string
 */
export function escapeRegexCharacters(string = '') {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

export function stringToSearchRegex(string = '') {
    return new RegExp(escapeRegexCharacters(string).replace(' ', '.*'), 'i')
}

export function checkIsTimeAfter(
    { hour: startHour, minute: startMinute, second: startSecond },
    { hour: endHour, minute: endMinute, second: endSecond },
) {
    if (startHour > endHour) return false

    if (
        startHour === endHour &&
        startMinute !== null &&
        endMinute !== null &&
        startMinute > endMinute
    )
        return false

    if (
        startHour === endHour &&
        startMinute === endMinute &&
        startSecond !== null &&
        endSecond !== null &&
        startSecond > endSecond
    )
        return false

    return true
}

export function initScrolledToBottomCheck(callback) {
    const onScrollToBottom = () => {
        if (
            window.innerHeight + document.documentElement.scrollTop <
            document.body.offsetHeight - 50
        ) {
            return
        }

        callback()
    }

    const debouncedScrollToBottom = (() => {
        let timeout = null

        return () => {
            clearTimeout(timeout)
            timeout = setTimeout(onScrollToBottom, 300)
        }
    })()

    onMounted(() => {
        window.addEventListener('scroll', debouncedScrollToBottom)
    })

    onUnmounted(() => {
        window.removeEventListener('scroll', debouncedScrollToBottom)
    })
}

export function addEventListeners(events, callback) {
    events.split(' ').forEach((event) => {
        window.addEventListener(event, callback)
    })
}

export function removeEventListeners(events, callback) {
    events.split(' ').forEach((event) => {
        window.removeEventListener(event, callback)
    })
}

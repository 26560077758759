<template>
    <q-img :src="src" :ratio="ratio" :width="width" v-if="src">
        <template v-slot:error>
            <img
                src="@/assets/persons_placeholder.png"
                class="base-photo__placeholder"
            />
        </template>
    </q-img>
    <div v-else class="base-photo__empty-image" :style="{ width }">
        <img src="@/assets/persons_placeholder.png" :style="{ width }" />
    </div>
</template>

<script>
export default {
    name: 'BasePhoto',
    props: {
        src: {
            type: String,
            default: '',
        },
        ratio: {
            type: Number,
            default: 1,
        },
        width: {
            type: String,
            default: undefined,
        },
    },
}
</script>

<style lang="scss" scoped>
.base-photo {
    &__placeholder {
        width: 100%;
        height: 100%;
        object-fit: contain;
        padding: 0.5em;
    }

    &__empty-image {
        padding-bottom: 100%;
        position: relative;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding: 1em;
            object-fit: contain;
        }
    }
}
</style>

import './styles/quasar.scss'
import '@quasar/extras/roboto-font/roboto-font.css'
import '@quasar/extras/material-icons/material-icons.css'

// To be used on app.use(Quasar, { ... })
export default {
    config: {
        brand: {
            primary: '#105efb',
            secondary: '#26A69A',
            accent: '#9C27B0',

            dark: '#222222',
            'dark-page': '#121212',

            positive: '#21BA45',
            negative: '#f81c41',
            info: '#31CCEC',
            warning: '#F2C037',

            pink: '#fb1085',
        },
    },
    plugins: {},
}

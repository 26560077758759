<template>
    <div ref="rangeSlider" class="base-range"></div>
</template>

<script>
import rangeSlider from 'range-slider-input'
import 'range-slider-input/dist/style.css'

const events = {
    updateModelValue: 'update:modelValue',
}

export default {
    name: 'BaseRange',
    props: {
        min: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
            default: 1,
        },
        modelValue: {
            type: Object,
            default: () => ({}),
        },
    },
    emits: Object.values(events),
    data() {
        return {
            rangeSliderElement: null,
        }
    },
    computed: {
        currentValue() {
            return [
                this.modelValue.min || this.min,
                this.modelValue.max || this.max,
            ]
        },
    },
    watch: {
        modelValue() {
            if (!this.rangeSliderElement) {
                return
            }

            const value = this.rangeSliderElement.value()
            if (
                this.currentValue[0] === value[0] &&
                this.currentValue[1] === value[1]
            ) {
                return
            }

            this.rangeSliderElement.value(this.currentValue)
        },
        min() {
            this.rangeSliderElement.min(this.min)
        },
        max() {
            this.rangeSliderElement.max(this.max)
        },
    },
    beforeUnmount() {
        this.rangeSliderElement.removeGlobalEventListeners()
    },
    mounted() {
        this.rangeSliderElement = rangeSlider(this.$refs.rangeSlider, {
            min: this.min,
            max: this.max,
            value: this.currentValue,
            onInput: this.onUpdateRange.bind(this),
        })
    },
    methods: {
        onUpdateRange(value, userInteraction) {
            if (!userInteraction) {
                return
            }

            this.$emit(events.updateModelValue, {
                min: value[0],
                max: value[1],
            })
        },
    },
}
</script>

<style lang="scss">
.base-range {
    background: #e0e0e0;
    height: 5px;

    .range-slider {
        &__range {
            background: #105efb;
            transition: height 0.3s;
            height: 5px;

            &[data-active] {
                height: 15px;
            }
        }

        &__thumb {
            width: 15px;
            height: 15px;
            background: #105efb;
            transition: transform 0.3s;
            cursor: grab;

            &[data-active] {
                transform: translate(-50%, -50%) scale(1.25);
                cursor: grabbing;
            }

            &:focus {
                box-shadow: 0 0 0 6px rgb(33 150 243 / 50%);
            }
        }
    }
}
</style>

<template>
    <q-header elevated class="bg-white">
        <q-toolbar class="default-header-content">
            <router-link :to="{ name: 'Shops' }">
                <q-avatar
                    class="default-header-content__logo"
                    size="32px"
                    square
                >
                    <img src="@/assets/logo.svg" />
                </q-avatar>
            </router-link>
            <q-toolbar-title class="row">
                <div
                    class="default-header-content__title"
                    @click="redirectToHome"
                >
                    <span class="text-dark text-weight-bold">Маркетинг</span>
                </div>
            </q-toolbar-title>
            <BaseButton
                label="Выйти"
                color="primary"
                @click="onClickLogout"
                v-if="isUserLoggedIn"
            />
        </q-toolbar>
    </q-header>
</template>

<script>
import { useUserStore } from '@/stores/user'
import { useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'

export default {
    name: 'DefaultHeader',
    setup() {
        const userStore = useUserStore()
        const { isUserLoggedIn } = storeToRefs(userStore)
        const { logoutUser } = userStore
        const router = useRouter()

        const onClickLogout = async () => {
            await logoutUser()
            router.push({ name: 'Login' })
        }

        const redirectToHome = () => {
            router.push({ name: 'Shops' })
        }

        return {
            logoutUser,
            isUserLoggedIn,
            onClickLogout,
            redirectToHome,
        }
    },
}
</script>

<style lang="scss" scoped>
.default-header-content {
    max-width: $containerMaxWidth;
    margin: auto;

    &__logo {
        cursor: pointer;
    }

    &__title {
        cursor: pointer;
        margin-top: 6px;
        font-size: 17px;
    }
}
</style>

<template>
    <canvas ref="chartCanvas" />
</template>

<script>
import { onMounted, ref, shallowRef, toRefs, watch } from 'vue'
import Chart from 'chart.js/auto'
import autocolors from 'chartjs-plugin-autocolors'
import ChartDataLabels from 'chartjs-plugin-datalabels'

Chart.register(autocolors, ChartDataLabels)

export default {
    name: 'BaseChart',
    props: {
        chartType: {
            type: String,
            required: true,
            validator: (v) => ['bar', 'pie'].includes(v),
        },
        labels: {
            type: Array,
            default: () => [],
        },
        datasets: {
            type: Array,
            default: () => [],
        },
        options: {
            type: Object,
            default: () => ({}),
        },
    },
    setup(props) {
        const chart = shallowRef(null)
        const chartCanvas = ref(null)

        const { labels, datasets, chartType, options } = toRefs(props)

        watch([labels, datasets], () => {
            const changes = chart.value.data.datasets.reduce(
                (acc, { data, backgroundColor }, i) => {
                    const newData = datasets.value[i]
                    acc.dataChanged = acc.dataChanged || newData.data !== data
                    acc.colorsChanged =
                        acc.colorsChanged ||
                        newData.backgroundColor !== backgroundColor
                    return acc
                },
                { dataChanged: false, colorsChanged: false },
            )

            if (changes.dataChanged) {
                chart.value.data.datasets = datasets.value
            }

            if (changes.colorsChanged) {
                chart.value.data.datasets.forEach(
                    (dataset, i) =>
                        (dataset.backgroundColor =
                            datasets.value[i].backgroundColor),
                )
            }

            if (chart.value.data.labels !== labels.value) {
                chart.value.data.labels = labels.value
            }

            chart.value.update()
        })

        const initChart = () => {
            const context = chartCanvas.value.getContext('2d')

            chart.value = new Chart(context, {
                type: chartType.value,
                data: {
                    labels: labels.value,
                    datasets: datasets.value,
                },
                options: options.value,
            })
        }

        onMounted(initChart)

        return {
            chart,
            chartCanvas,
        }
    },
}
</script>

<template>
    <div>
        <q-input
            filled
            :modelValue="modelValue"
            :dense="dense"
            readonly
            mask="##.##.####"
        >
            <template v-slot:append>
                <q-icon name="event" class="cursor-pointer"> </q-icon>
            </template>
            <q-menu
                transition-show="scale"
                transition-hide="scale"
                v-model="isVisible"
            >
                <q-date
                    :modelValue="modelValue"
                    @update:modelValue="onUpdate"
                    :locale="locale"
                    minimal
                    mask="DD.MM.YYYY"
                    v-bind="$attrs"
                    :options="options"
                    :dense="dense"
                    no-unset
                />
            </q-menu>
        </q-input>
    </div>
</template>

<script>
const events = {
    updateModelValue: 'update:modelValue',
}
export default {
    name: 'BaseDatePicker',
    props: {
        modelValue: {
            type: String,
            default: '',
        },
        options: {
            type: [Object, Function],
            default: () => ({}),
        },
        dense: {
            type: Boolean,
            default: false,
        },
    },
    emits: Object.values(events),
    data() {
        return {
            isVisible: false,
        }
    },
    computed: {
        locale() {
            return {
                days: 'Воскресенье_Понедельник_Вторник_Среда_Чертверг_Пятница_Суббота'.split(
                    '_',
                ),
                daysShort: 'Вс_Пн_Вт_Ср_Чт_Пт_Сб'.split('_'),
                months: 'Январь_Февраль_Март_Апрель_Май_Июнь_Июль_Август_Сентябрь_Октябрь_Ноябрь_Декабрь'.split(
                    '_',
                ),
                monthsShort:
                    'Янв_Фев_Мар_Апр_Май_Июн_Июл_Авг_Сен_Окт_Ноя_Дек'.split(
                        '_',
                    ),
                firstDayOfWeek: 1,
                format24h: true,
                pluralDay: 'дней',
            }
        },
    },
    methods: {
        onUpdate(e) {
            this.$emit(events.updateModelValue, e)

            this.isVisible = false
        },
    },
}
</script>

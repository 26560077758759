<template>
    <Transition :name="transitionName">
        <div class="row justify-center fixed-center" v-if="isLoading">
            <q-spinner-dots color="primary" size="2em" />
        </div>
        <slot v-else />
    </Transition>
</template>

<script>
export default {
    name: 'BasePageLoader',
    props: {
        isLoading: {
            type: Boolean,
            default: true,
        },
        transitionName: {
            type: [String, undefined],
            default: 'fade',
        },
    },
}
</script>

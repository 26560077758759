<template>
    <DefautLayout>
        <RouterView />
    </DefautLayout>
    <TheErrorAlert />
</template>

<script>
import DefautLayout from '@/components/Layouts/DefautLayout'
import TheErrorAlert from '@/components/TheErrorAlert.vue'

export default {
    name: 'App',
    components: {
        DefautLayout,
        TheErrorAlert,
    },
}
</script>

import { defineStore } from 'pinia'

export const useCommonStore = defineStore('common', {
    state: () => ({
        error: null,
    }),
    getters: {
        hasError(state) {
            return Boolean(state.error)
        },
    },
    actions: {
        setError(error) {
            this.error = error
        },
    },
})
